import { IDate, IRecordNotice } from '../types';

export const parseBoolean = (value: any): boolean | null => {
    if (value === '' || value === null) {
        return null;
    }

    if (typeof value === 'boolean') {
        return value;
    }

    return parseInt(value) === 1;
};

export const parseDate = (dateString: string | null): IDate =>
    dateString ? new Date(dateString) : null;

export const parseResponseInt = (value: any): number => {
    if (!value) {
        return 0;
    }

    return parseInt(value, 10);
};

export const mapRecordNotice = (apiResponse: any): IRecordNotice => ({
    id: apiResponse.id,
    content: apiResponse.content,
    author: apiResponse.author || '',
    created_at: parseDate(apiResponse.created_at),
    updated_at: parseDate(apiResponse.updated_at),
});
