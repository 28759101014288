import { parseResponseInt } from '../funcs/map-entities';
import mapUser, {
    mapUsers,
    User,
} from '../../../../Modules/Crm/Resources/assets/react/mappers/userMapper';

export interface Office {
    name: string;
    unique: string;
    email: string;
    short: string;
    id: number;
    crm_restricted: boolean;
    crm_members?: User[];
    assigned_crm_user_id: string | null;
    assigned_crm_user?: User;
}

export interface OfficeFull extends Office {
    open_tasks_count: number;
    records_count: number;
}

const mapOffice: (response: Obj) => Office = response => ({
    ...response,
    ...(response.crm_members && {
        crm_members: mapUsers(response.crm_members),
    }),
    ...(response.assigned_crm_user && {
        assigned_crm_user: mapUser(response.assigned_crm_user),
    }),
});

export const mapOffices: (response: Obj) => Office[] = response =>
    response.map(mapOffice);

export const mapOfficeFull: (response: Obj) => OfficeFull = response => ({
    ...mapOffice(response),
    open_tasks_count: parseResponseInt(response.open_tasks_count),
    records_count: parseResponseInt(response.records_count),
});

export default mapOffice;
